import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import { setupAuth } from "./services/auth"
import { setupApi } from "./services/api"
import axios from "axios"
import { LDPlugin } from 'launchdarkly-vue-client-sdk'
import { datadogRum } from '@datadog/browser-rum';
import FloatingVue from 'floating-vue'

import 'floating-vue/dist/style.css'
import "./assets/base.css"

function callbackRedirect(appState) {
  return router.push(appState && appState.targetUrl ? appState.targetUrl : "/")
}

const main = async () => {
  const res = await axios.get("/config/auth")
    if (!res.data.domain || !res.data.clientId || !res.data.audience) {
      console.error(
        "Could not initialize authentication. Missing data.",
        res.data
      )
    }

    const ddogConfigRes = await axios.get("/config/ddog")
    if (!ddogConfigRes.data.token || !ddogConfigRes.data.applicationId || !ddogConfigRes.data.environment) {
        console.error("Could not initialize DDOG RUM. Missing data.")
    } else {
        // Keep this version updated with the Makefile
        datadogRum.init({
            applicationId: ddogConfigRes.data.applicationId,
            clientToken: ddogConfigRes.data.token,
            env: ddogConfigRes.data.environment,
            site: ddogConfigRes.data.site || 'datadoghq.com',
            service: ddogConfigRes.data.service || 'admin-portal',
            version: '1.5.7',
            trackResources: true,
            sampleRate: 100,
            trackLongTasks: true,
            trackInteractions: true,
        })
    }

    let app = createApp(App)
        .use(router);

    app.use(await setupAuth(
      {
        domain: res.data.domain,
        client_id: res.data.clientId,
        redirect_uri: `${window.location.origin}/LoginCallback`,
        audience: res.data.audience,
      },
      callbackRedirect
    ));

    app.use(await setupApi());

    const ldConfig = (await axios.get("/config/ld")).data;
    if (!ldConfig || !ldConfig.clientId) {
        console.error("Could not load LaunchDarkly");
    } else {
        app.use(LDPlugin, { clientSideID: ldConfig.clientId, deferInitialization: true });
    }

    app.use(FloatingVue, {
        themes: {
            'bombora-tooltip': {
                $extend: 'tooltip',
                $resetCss: true,
                placement: 'top',
                delay: 300
            }
        }
    })

    // Set api service globally so any component can use it.
    app.config.globalProperties.$api = store(app.config.globalProperties.$apiService);

    return app.mount("#app");
}

// Execute async function
/* eslint-disable-next-line no-unused-vars */
main().then(() => {
  console.debug("App Mounted");
});
