<template>
  <div>
    <!-- loading spinner -->
    <div v-if="loading" class="text-center">
      <div class="inline-block">
        <loading-spinner />
      </div>
    </div>

    <div v-if="!loading && account">
      <account-breadcrumbs :account="account" />

      <form @submit.prevent="onSubmit">
        <card>
          <!-- header -->
          <template #header>
            <div class="flex">
              <manage-page-heading heading="Visitor Insights" :details="!product.enabled ? 'Enable this product to modify features' : ''"></manage-page-heading>

              <div class="self-end">
                <btn v-if="product.enabled" @click="onDisableProductClick">Disable</btn>
                <btn v-if="!product.enabled" @click="onEnableProductClick">Enable</btn>
              </div>
            </div>
          </template>


          <!-- fields -->
          <div :class="[`${!product.enabled ? 'text-gray' : ''}`]">
            <validated-field-group label="Service Period">
              <template #details>
                <p>Start and end date</p>
              </template>
              <div class="flex justify-between items-center">
                <date-picker v-model="product.startDate" name="vi.startDate" :disabled="!product.enabled" />

                <p class="px-1.5">to</p>

                <date-picker v-model="product.endDate" name="vi.endDate" :disabled="!product.enabled" />
              </div>

              <template #validation-message>
                <FieldErrorMessage :field="v$.product.startDate"></FieldErrorMessage>
              </template>
            </validated-field-group>


            <validated-field-group label="Max Sections">
              <text-field v-model="product.maxSections" name="vi.maxSections" placeholder="0" :disabled="!product.enabled" />
              <template #validation-message>
                <FieldErrorMessage :field="v$.product.maxSections"></FieldErrorMessage>
              </template>
            </validated-field-group>

            <validated-field-group label="Standalone Tag" :wideLabel="true">
              <enable-status-toggle :disabled="true" name="account.isPcce" v-model="account.isPcce" />
              <template #details>
                Allows access to VI without contributing this customer's data to the data co-op (IsPCCE).
              </template>
            </validated-field-group>
          </div>

          <template v-slot:footer>
            <btn type="submit" color="orange" :is-disabled="!product.enabled || saving">
              {{ saving ? "Saving..." : "Save" }}
            </btn>
            <Transition>
              <alert v-if="saveSuccess" style-type="success" class="mt-4">
                Your changes have been saved.
              </alert>
            </Transition>
            <Transition>
              <alert v-if="saveFailed" style-type="warning" class="mt-4">
                There was a problem saving your changes. Please try again later.
              </alert>
            </Transition>
            <Transition>
              <alert v-if="formInvalid" style-type="warning" class="mt-4">
                Please correct the errors in the form and resubmit.
              </alert>
            </Transition>
          </template>
        </card>
      </form>
    </div>

    <Transition>
      <product-disable-confirm-modal :show="showConfirmModal" product-name="Visitor Insights" @cancel="modalCancelHandler" @confirm="modalConfirmHandler" />
    </Transition>
  </div>
</template>

<script>
  import { Alert, Btn, Card, DatePicker, LoadingSpinner, TextField } from "@bombora/component-library";

  import AccountBreadcrumbs from "@/components/shared/AccountBreadcrumbs";
  import FieldErrorMessage from "@/components/shared/FieldErrorMessage";
  import EnableStatusToggle from "@/components/shared/EnableStatusToggle";
  import ManagePageHeading from "@/components/shared/ManagePageHeading";
  import ProductDisableConfirmModal from "@/components/shared/ProductDisableConfirmModal";
  import ValidatedFieldGroup from "@/components/shared/ValidatedFieldGroup";

  import { useVuelidate } from '@vuelidate/core'
  import { minValue, helpers } from '@vuelidate/validators'

  const productName = 'visitor_insights';

  export default {
    name: "ProductVisitorInsights",
    components: {
      AccountBreadcrumbs,
      Alert,
      Btn,
      Card,
      DatePicker,
      EnableStatusToggle,
      FieldErrorMessage,
      LoadingSpinner,
      ManagePageHeading,
      ProductDisableConfirmModal,
      TextField,
      ValidatedFieldGroup,
      ValidatedFieldGroup,
    },
    setup: () => ({ v$: useVuelidate() }),
    // inherited from pages/account/Page
    props: ["account", "loading"],
    data() {
      return {
        product: {},
        saving: false,
        saveSuccess: false,
        saveFailed: false,
        formInvalid: false,
        showConfirmModal: false,
      }
    },
    mounted() {
      this.getDataFromAccount()
    },
    validations() {
      return {
        product: {
          startDate: {
            maxValue: helpers.withMessage('Start Date must be before End Date', value => {
              // Make it nullable
              if (!value || !this.product.endDate) return true;
              return new Date(value) < new Date(this.product.endDate)
            }),
          },
          maxSections: {
            minValue: helpers.withMessage('Max Sections must be greater than or equal to zero', minValue(-1)),
          },
        }
      }
    },
    watch: {
      account: {
        handler: "getDataFromAccount",
        immediate: true
      }
    },
    methods: {
      onDisableProductClick() {
        this.saveSuccess = false;
        // If the product is currently active, show the modal for disable
        this.showConfirmModal = true;
      },
      modalCancelHandler() {
        this.showConfirmModal = false;
      },
      modalConfirmHandler() {
        this.product.enabled = false;
        this.saveSuccess = false;
        this.modalCancelHandler();
      },
      onEnableProductClick() {
        this.product.enabled = true;
      },
      async onSubmit() {
        const isFormCorrect = await this.v$.$validate()
        if (!isFormCorrect) {
          console.debug(this.v$.$errors);
          this.formInvalid = true;
          setTimeout(() => this.formInvalid = false, 5000)
          return
        }
        else {
          this.saving = true
          this.$api.updateProduct(this.account.id, productName, this.product)
            .then(() => {
              this.saving = false
              this.saveSuccess = true
              setTimeout(() => this.saveSuccess = false, 5000)
            })
            .catch((err) => {
              this.saving = false
              this.saveFailed = true;
              setTimeout(() => this.saveFailed = false, 5000)
              console.error(err.message)
            })
        }
      },
      getDataFromAccount() {
        if (this.account && this.account.products) {
          this.product = this.account.products.find(p => p.id === productName) || {}
        }
      },
    }
  };
</script>
